import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import { ErrorPage } from './pages/error/ErrorPage';
import { HashRouter, Route, Routes } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyle';
import ToastProvider from './contexts/toastContext/toastContext';
import ReinitialiserMotDePasse from './pages/ReinitialiserMotDePasse/ReinitialiserMotDePasse';
import SinistreContextProvider from './contexts/sinistreContext/SinistreContext';
//import Accueil from './pages/accueil/Accueil';
import FormAssure from './pages/formAssure/FormAssure';
import FormAssurance from './pages/formAssurance/FormAssurance';
import FormCirconstance from './pages/formCirconstance/FormCirconstance';
import FormSignatureConvention from './pages/signatureConvention/FormSignatureConvention';
import FormDocumentAnnexe from './pages/formDocumentAnnexe/FormDocumentAnnexe';
import ListeDossierClient from './pages/listeDossierClient/ListeDossierClient';
import DossierClient from './pages/DossierClient/DossierClient';
import FormFacture from './pages/formFacturation/FormFacture';
import CentreParametrage from './pages/centreParametrage/CentreParametrage';
import AuthRoute from './components/common/authentification/routeAuth/RouteAuth';
import Login from './pages/login/Login';
import MotDePasseOublie from './pages/motDepasseOublie/MotDePasseOublie';
import AuthProvider from './contexts/authContext/AuthContext';
import GtMotiveContextProvider from './contexts/gtMotiveContext/GtMotiveContext';
import ConfirmActivationSuccess from './pages/compteActive/ConfirmActivationSuccess';
import ConfirmActivationFail from './pages/compteActive/ConfirmActivationFail';
import CreditContextProvider from './contexts/creditcContext/CreditContext';
import Test from './pages/Test';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastProvider>
        <HashRouter>
          <AuthProvider>
            <SinistreContextProvider>
              <GtMotiveContextProvider>
                <CreditContextProvider>
                  <Routes>
                    <Route path="/" element={<AuthRoute isAuth={false} Component={Test} />} />
                    {/*<Route path="/" element={<AuthRoute isAuth={true} Component={Accueil} />} />*/}

                    <Route path="/active/fail" element={<AuthRoute isAuth={false} Component={ConfirmActivationFail} />} />
                    <Route path="/active/success" element={<AuthRoute isAuth={false} Component={ConfirmActivationSuccess} />} />
                    <Route path="/connexion" element={<AuthRoute isAuth={false} Component={Login} />} />
                    <Route path="/password/forget" element={<AuthRoute isAuth={false} Component={MotDePasseOublie} />} />
                    <Route path="/password/reset/:userId/:token" element={<AuthRoute isAuth={false} Component={ReinitialiserMotDePasse} />} />

                    <Route path="/declaration/assure/:idSinistre?" element={<AuthRoute isAuth={true} Component={FormAssure} />} />
                    <Route path="/declaration/assurance/:idSinistre" element={<AuthRoute isAuth={true} Component={FormAssurance} />} />
                    <Route path="/declaration/circonstance/:idSinistre" element={<AuthRoute isAuth={true} Component={FormCirconstance} />} />
                    <Route path="/declaration/convention/:idSinistre" element={<AuthRoute isAuth={true} Component={FormSignatureConvention} />} />
                    <Route path="/declaration/documents/:idSinistre" element={<AuthRoute isAuth={true} Component={FormDocumentAnnexe} />} />

                    <Route path="/dossier/client" element={<AuthRoute isAuth={true} Component={ListeDossierClient} />} />
                    <Route path="/dossier/complet/:idSinistre" element={<AuthRoute isAuth={true} Component={DossierClient} />} />

                    <Route path="/dossier/facture/:idSinistre" element={<AuthRoute isAuth={true} Component={FormFacture} />} />
                    <Route path="/parametrage/centre" element={<AuthRoute isAuth={true} Component={CentreParametrage} />} />

                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </CreditContextProvider>
              </GtMotiveContextProvider>
            </SinistreContextProvider>
          </AuthProvider>
        </HashRouter>
      </ToastProvider>
    </ThemeProvider>
  );

}

export default App;
