import styled from "styled-components";
import { Camera, XCircle } from 'react-bootstrap-icons';
import { theme } from "../../../../theme/theme";

export type InputFileVhlStyleProps = {
    $color?: string,
    $colorLabel?: string,
    $nameDocument? : string | null
};

export const BoxGlobalInputFileVhl = styled.div<InputFileVhlStyleProps>`
    width: 100%; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const BoxInputFileVhl = styled.div<InputFileVhlStyleProps>`
    position: relative; 
    width: 100%;
    padding: 5px 10px; 
    border: dashed 2px ${props => props.$color};
    background-color: white; 
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        height: 41px;
        display: flex;
    }
`;

export const BoxInputFileVhlIconClose = styled.div<InputFileVhlStyleProps>`
    position: relative; 
    width: 100%; 
    height: 48px;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        height: 41px;
    }
`;

export const BoxCameraVhl = styled.div`
    display: none; 
    margin-right: 10px; 
    position: relative;
    height: 48px; 
    width: 55px; 
    border: solid 2px #0288d1; 
    border-radius: 4px; 
    background-color: #0288d1; 
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        display: flex; 
        align-items: center; 
        justify-content: center;
    }
    @media screen and (max-width: 767px) {
        height: 41px;
        display: flex; 
        align-items: center; 
        justify-content: center;
    }
`;

export const BoxIconLabelVhl = styled.div`
    width: 100%;
    background-color: transparent;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start; 
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
`;

export const CameraVhl = styled(Camera)<InputFileVhlStyleProps>`
    color: ${props => props.$colorLabel};
    margin-right: 20px;
    font-size: 35px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        font-size: 20px;
   }
`;

export const CameraVhlCustom = styled(Camera)<InputFileVhlStyleProps>`
    color: ${props => props.$colorLabel};
    font-size: 35px;
    &:hover{
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
         font-size: 20px;
    }
`;


export const IconRestFileVhl = styled(XCircle)`
    font-size: 20px; 
    right: -40px; 
    color: ${theme.color.alert};
    &:hover{
        cursor: pointer;
        opacity: 0.6;
    }
    @media screen and (max-width: 767px) {
        font-size: 15px; 
    }
`; 

export const LabelInputFileVhl = styled.label<InputFileVhlStyleProps>`
    color: #616161;
    font-size: ${props => props.theme.font.medium};
    &:hover{ 
        cursor: pointer;
    }
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.small};
    }
`;

export const InputFileVhlField = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
`;

export const InputFileVhlFieldPhoto = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 4px;
    &:hover{
        cursor: pointer;
    }
`;

export const ErrorInputFileVhl = styled.p`
    margin-top: 5px;
    color: ${theme.color.alert}; 
    font-size: 16px;
    height: 16px;
`;

export const BoxFileNameInputFileVhl = styled.div`
    width: 100%;
`;

export const BoxFileNameIconDeleteInputFileVhl = styled.div`
    width: 100%;
    background-color: ${theme.color.white}; 
    border: solid 2px ${theme.color.grayMedium}; 
    border-radius: ${theme.css.radius}; 
    padding: 5px; 
    margin-top: 5px; 
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between;
`;
export const FileNameInputFileVhl = styled.div`
    font-size: 16px;
    color: ${theme.color.letterGray};
    font-family: Roboto-Regular;
    @media screen and (max-width: 767px) {
        font-size: ${theme.font.small};
    }
`;



