import { useEffect, useState } from "react";
import BasePage from "../components/common/basePage/BasePage";
import { TilePage } from "../components/common/basePage/BasePageStyle";
import Header from "../components/common/header/header/Header";
import { BoxFieldDocumentAnnexe, BoxFieldFormDocumentAnnexe } from "./formDocumentAnnexe/FormDocumentAnnexeStyle";
import { Form } from "./formAssure/FormAssureStyle";
import { DocumentsErreur } from "../shared/class/DocumentsErreur";
import InputFileVhl from "../components/common/field/inputFileVhl/InputFileVhl";

const Test = (): JSX.Element => {

    const [photosVehiculeFile, setPhotosVehiculeFile] = useState<FileList>();
    const [fileError, setFileError] = useState<DocumentsErreur>(new DocumentsErreur());

    useEffect(() =>{
        console.log(photosVehiculeFile);
    },[photosVehiculeFile])
  

    return (
        <BasePage>
            <Header />

            <TilePage>Test</TilePage>

            <Form>
                <BoxFieldFormDocumentAnnexe>
                    <BoxFieldDocumentAnnexe>
                        <InputFileVhl
                            setFile={setPhotosVehiculeFile}
                            label="Photos du véhicule (format .png ou .jpeg)"
                            setError={setFileError}
                            error={fileError.photosVehiculeFile}
                            nameError="photosVehiculeFile"
                            require={true}
                        />
                    </BoxFieldDocumentAnnexe>
                </BoxFieldFormDocumentAnnexe>
            </Form>
        </BasePage>
    );
};

export default Test;